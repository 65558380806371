import React from "react";

import {connect} from "react-redux";
import {Alert} from "antd";
import {checkPermissions} from "./functions";

class PermissionAlert extends React.Component {

    render() {
        const {permissions = [],force} = this.props

        if (checkPermissions(permissions) && !force) {
            return this.props.children
        }

        return (
            <div style={{
                height: "inherit",
                width: "inherit",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Alert
                    message="Non Autorisé"
                    description="Vous n'avez pas les droits requis pour accéder a cette partie du logiciel."
                    type="error"
                    showIcon
                />
            </div>
        )
    }
}

const mapStateToProps = (state => {
    return {
        access: state.auth.access
    }
})

export default connect(mapStateToProps)(PermissionAlert)
