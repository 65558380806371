import React from "react";

import {connect} from "react-redux";
import {Alert,Typography} from "antd";
import {checkForfait} from "./functions";

const {Text}=Typography

class ForfaitAlert extends React.Component {

    render() {
        const {forfaits = [],force} = this.props

        if (checkForfait(forfaits) && !force) {
            return this.props.children
        }

        return (
            <div style={{
                height: "inherit",
                width: "inherit",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Alert
                    message="Accès non Autorisé"
                    description={(<Text>Vous n'avez pas l'offre requise pour accéder à cette partie du logiciel.<br/>Contactez le service client afin de changer d'offre.</Text>)}
                    type="warning"
                    showIcon
                />
            </div>
        )
    }
}

const mapStateToProps = (state => {
    return {
        access: state.auth.access
    }
})

export default connect(mapStateToProps)(ForfaitAlert)
